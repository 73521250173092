import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const FooterLink = ({ text, target, to, href }) => {
  const linkClasses = 'gray hover-black no-underline'
  return (
    <li className="ph2">
      {to
        ? <Link to={to} className={linkClasses}>{text}</Link>
        : <a href={href} className={linkClasses} target={target}>{text}</a>
      }
    </li>
  )
}

FooterLink.propTypes = {
  target: PropTypes.string,
  text: PropTypes.node,
  to: PropTypes.string,
  href: PropTypes.string
}

export default FooterLink
