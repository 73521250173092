import React from 'react'

import Container from '@/container'

import HeaderLogo from './header-logo'
import HeaderLinks from './header-links'

const Header = () => (
  <header className="bb b--moon-gray">
    <Container>
      <div className="dark-gray flex flex-wrap items-center justify-between pv3">
        <HeaderLogo />
        <HeaderLinks />
      </div>
    </Container>
  </header>
)

export default Header
