import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Container = ({ children, fullWidth }) => (
  <div
    className={classNames({
      'w-100 mw8 ph3 ph4-m center': true,
      'mw-none': fullWidth
    })}
  >
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.node
}

export default Container
