import React from 'react'
import Helmet from 'react-helmet'

import appleTouchIcon from './apple-touch-icon.png'
import favicon32x32 from './favicon-32x32.png'
import favicon16x16 from './favicon-16x16.png'
import safariPinnedTab from './safari-pinned-tab.svg'
import favicon from './favicon.ico'

const Favicons = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    <link rel="mask-icon" href={safariPinnedTab} color="#27c081" />
    <link rel="shortcut icon" href={favicon} />
    <meta name="msapplication-TileColor" content="#27c081" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
)

export default Favicons
