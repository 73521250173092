import React from 'react'
import config from 'config'

const HeaderLinks = () => {
  const item = (url, text) => (
    <li className="mh3">
      <a
        href={url}
        className="f12 fw5 dim no-underline sans-serif black pv2 dib"
      >
        {text}
      </a>
    </li>
  )
  return (
    <nav>
      <ul className="flex items-center flex-wrap mr--3">
        {item(`${config.host}/contests`, 'Contests')}
        {item(`${config.adminHostUrl}/contest/create`, 'Studio')}
      </ul>
    </nav>
  )
}

export default HeaderLinks
