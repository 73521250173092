import React from 'react'

import Footer from '@/footer'
import Header from '@/header'

import Favicons from './favicons'
import './tachyons.min.css'
import './global.css'

const Layout = ({ children }) => (
  <div className="layout-body flex flex-column min-vh-100 sans-serif dark-gray lh-copy f10">
    <Favicons />
    <Header />
    <main className="flex-grow-1 bg-near-white">{children}</main>
    <Footer />
  </div>
)

export default Layout
