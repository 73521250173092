import React from 'react'

import Icon from '@/icon'
import Container from '@/container'

import FooterLink from './footer-link'

const Footer = () => {
  return (
    <footer className="bt b--moon-gray f11">
      <Container>
        <div className="gray pv3 pv4-m flex flex-column flex-row-m items-center justify-between">
          <div className="mb3 mb0-m flex items-center">
            <Icon icon="logoStar" size="20px" className="mr3" />
            &copy; Choicely, Inc.
          </div>

          <ul className="list mv0 mh--2 flex items-center">
            <FooterLink to="/terms" text="Terms" />
            <FooterLink to="/privacy" text="Privacy" />
            <FooterLink
              href="https://www.facebook.com/choicelyapp/"
              text={<Icon icon="facebook" size="15px" className="db" />}
              target="_blank"
            />
            <FooterLink
              href="https://twitter.com/choicelyapp"
              text={<Icon icon="twitter" size="15px" className="db" />}
              target="_blank"
            />
            <FooterLink
              href="https://instagram.com/choicelyapp"
              text={<Icon icon="instagram" size="15px" className="db" />}
              target="_blank"
            />
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
