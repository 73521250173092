import React from 'react'

import Icon from '@/icon'

const HeaderLogo = () => (
  <a href="https://choicely.com/" className="db current-color">
    <Icon icon="logo" width="87px" height="21px" className="db" />
  </a>
)

export default HeaderLogo
